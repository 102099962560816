import React from "react";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";

import { msalConfig } from "./MicrosoftAuthConfig";
import { AzureSignIn } from "./AzureSignIn";
// 
// import "./style.css"

const pca = new PublicClientApplication(msalConfig);

function MicrosoftAuthButton({ setLoginErrorMessage}) {
    const { instance } = useMsal();

    // Sign-in logic has to be in another component inside of MsalProvider
	return (
		<div>
			<MsalProvider instance={pca}>
				<AzureSignIn 
					setLoginErrorMessage={setLoginErrorMessage} 
					instance={instance}
				/>
			</MsalProvider>
		</div>
	);
}

export default MicrosoftAuthButton;