import React from "react";

import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

export const AzureSignIn = ({ setLoginErrorMessage }) => {
	const { instance } = useMsal();
	
    const signInError = () => {
        setLoginErrorMessage("Something went wrong. Please, try it again.", "")
    }

	const handleSignIn = (response) => {
		instance.loginRedirect({
			scopes: ["user.read"],
		});
	};

	const setUserInfo = (event => {
		localStorage.setItem("userName", event?.payload?.account?.name)
		localStorage.setItem("emailSignup", event?.payload?.account?.username)
		window.location.href = "/onboarding"
	})

	// https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress
	instance.addEventCallback(
		(event) => {
			// set active account after redirect
			if ((event?.eventType === EventType.LOGIN_SUCCESS || event?.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event?.payload?.account) {
				setUserInfo(event);
			} else if (event?.eventType === EventType.LOGIN_FAILURE || event?.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
				signInError();
			} 
		},
		(error) => {
			console.log("Error in Microsoft sign in callback:", error);
			signInError();
		}
	);

	return (
        <div onClick={handleSignIn} className="microsoft-signup-button">
			<img 
				className="microsoft-logo"
				src="assets/images/microsoft-logo.svg"
				alt="Microsoft Login"
			/>
			<span className="microsoft-signup-message">Sign up with Microsoft</span>
        </div>
    );
};